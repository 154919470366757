/*
 Card Styles  
*/
.card-box {
    &.card-style-1 {
        box-shadow: var(--shadow-lighter);
        background-color: var(--color-blackest);
        padding: 20px;
        border-radius: 5px;
        height: 100%; // Define a altura para 100%

        .inner {
            display: flex;
            flex-direction: column;
            height: 100%; // Certifica-se de que o inner ocupa toda a altura

            .image {
                img {
                    border-radius: 5px;
                }
            }

            .content {
                flex: 1 1;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 14px;
                    a {
                        color: inherit;
                        @extend %transition;
                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }

                .description {
                    margin-bottom: 0;
                    min-height: 150px; // Ajuste conforme necessário
                }

                .btn-default {
                    margin-top: auto; // Isso empurra o botão para a parte inferior
                }
            }
        }
    }
}
